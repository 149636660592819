<template>
  <div :class="{ 'attendance-menu-container': true, hidden: !opened }">
    <FormSelect v-if="!customFormEdit" />
    <Groups />
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  components: {
    FormSelect: () => import('@/components/Attendance/Menu/FormSelect'),
    Groups: () => import('@/components/Attendance/Menu/Groups'),
  },
  computed: {
    ...mapState({
      opened: state => state.attendance.menu.opened,
      customFormEdit: state => state.attendance.customFormEdit,
    })
  },
}
</script>
<style lang="scss" scoped>
  .attendance-menu-container {
    width: max-content;
    min-width: 360px;
    padding-bottom: 16px;
    background: white;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid var(--neutral-200);

    &.hidden {
      display: none !important;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-title {
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-blue);
      }
    }
  }
</style>
